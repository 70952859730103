.check-box{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: none;
    label{
        margin-bottom: 0;
    }
}
.tick{
    height: 100%;
    align-items: center;
    input{
        margin-right: 0.5rem;
    }
}

.email-imput{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
